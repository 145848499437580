import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Portal from './Areas/Administration/Portal';
import { AuthorizedProtect, AuthenticatedProtect } from './Areas/Auth/Components/RouteMiddlewares';
import CreateOrder from './Areas/orders/CreateOrder';
import OrderList from './Areas/orders/OrderList';
import Home from './Areas/Main/Home';
import { NavBar } from './Shared/components/NavBar';
import UserProfile from './Areas/Auth/Components/UserProfile';
import Unauthorized from './Areas/Auth/Components/Unauthorized';
import PageNotFound from './Areas/Auth/Components/PageNotFound';
import AdminRegister from './Areas/Auth/Components/AdminRegister';
import ResetPassword from './Areas/Auth/Components/ResetPassword';
import EmailConfirmationByUser from './Areas/Auth/Components/EmailConfirmationByUser';
import useAuth from './Shared/hooks/useAuth';
import { OrderDetail } from './Areas/orders/OrderDetail';
import { CreateStockUpdate } from './Areas/StockUpdates/CreateStockUpdate';
import { StockRequestsList } from './Areas/StockUpdates/StockRequestList';
import useIdleDetection from './Shared/contexts/userIdleDetection';
import { ImportersEntity } from './Areas/Entities/ImportersEntity';
import { DistributorsEntity } from './Areas/Entities/DistributorsEntity';
import { ProductsEntity } from './Areas/Entities/ProductsEntity';
import { StockRequestDetail } from './Areas/StockUpdates/StockRequestDetail';
import EditOrder from './Areas/orders/EditOrder';
import PasswordForgetEmailSubmission from './Areas/Auth/Components/PasswordForgetEmailSubmission';
import ChangePassword from './Areas/Auth/Components/ChangePassword';
import CreateDeliveryOrder from './Areas/deliveryOrders/CreateDeliveryOrder';
import { DeliveryOrderDetail } from './Areas/deliveryOrders/DeliveryOrderDetail';
import DeliveryOrderList from './Areas/deliveryOrders/DeliveryOrderList';
import EditDeliveryOrder from './Areas/deliveryOrders/EditDeliveryOrder';
import CreateFillingAdviceNote from './Areas/fan/CreateFillingAdviceNote';
import { FillingAdviceNoteDetail } from './Areas/fan/FillingAdviceNoteDetail';
import FillingAdviceNoteList from './Areas/fan/FillingAdviceNoteList';
import EditFillingAdviceNote from './Areas/fan/EditFillingAdviceNote';
import { EditStockUpdate } from './Areas/StockUpdates/EditStockUpdate';
import { AppFilterContextProvider } from './Shared/contexts/AppFilterContextProvider';
import Loading from './Shared/components/Loading';
import { StorageTankEntity } from './Areas/Entities/StorageTankEntity';
import { CreateStockTransfer } from './Areas/StockTransfers/CreateStockTransfer';
import { EditStockTransfer } from './Areas/StockTransfers/EditStockTransfer';
import { StockTransferDetail } from './Areas/StockTransfers/StockTransferDetail';
import { StockTransferList } from './Areas/StockTransfers/StockTransferList';
import { Reporting } from './Areas/Reporting/Reporting';
import './App.css';

function App() {
  const { rCodes, setUpAuth, isAuthenticated } = useAuth();
  const isValid = isAuthenticated();
  const timer = useIdleDetection(300, isValid);

  if (timer === 0 && isValid) {
    setUpAuth();
  }

  if (window.location.hostname.startsWith('www.lprc')) {
    return (
      <div className="container text-center pt-5">
        <div className='h5 text-danger'>The application cannot be accessed.</div>
      </div >)
  }

  if (!rCodes) {
    return (
      <div className="container text-center pt-5">
        <Loading position="center" />
        <div className='h5 pl-4'>Loading...</div>
      </div >);
  }

  return (
    <div className="home-bg">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AdminRegister/:tempCode" element={<AdminRegister />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/reset/password/*" element={<ResetPassword />} />
          <Route path="/email/confirmation/*" element={<EmailConfirmationByUser />} />
          <Route path="/forgot-password" element={<PasswordForgetEmailSubmission />} />

          <Route element={<AuthenticatedProtect />}>
            <Route path="/profile/:id" element={<UserProfile />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/importers/list" element={<ImportersEntity />} />
            <Route path="/distributors/list" element={<DistributorsEntity />} />
            <Route path="/products/list" element={<ProductsEntity />} />

            <Route element={<AuthorizedProtect roles={[rCodes.R500]} />}>
              <Route path="/admin-portal" element={<Portal />} />
            </Route>

            <Route element={<AuthorizedProtect roles={[rCodes.R100]} />}>
              <Route path="/submit-stock-update" element={<CreateStockUpdate />} />
              <Route path="/submit-stock-transfer" element={<CreateStockTransfer />} />
              <Route path="/edit-stock-request/:id" element={<EditStockUpdate />} />
              <Route path="/edit-stock-transfer/:id" element={<EditStockTransfer />} />
              <Route path="/create-transfer-order" element={<CreateOrder />} />
              <Route path="/edit-transfer-order/:id" element={<EditOrder />} />
            </Route>

            <Route element={<AuthorizedProtect roles={[rCodes.R100, rCodes.R500, rCodes.R600]} />}>
              <Route path="/stock-request/list" element={<AppFilterContextProvider><StockRequestsList /></AppFilterContextProvider>} />
              <Route path="/stock-request/detail/:id" element={<StockRequestDetail />} />
              <Route path="/stock-transfer/list" element={<AppFilterContextProvider><StockTransferList /></AppFilterContextProvider>} />
              <Route path="/stock-transfer/detail/:id" element={<StockTransferDetail />} />
            </Route>
            <Route element={<AuthorizedProtect roles={[rCodes.R100, rCodes.R200, rCodes.R300, rCodes.R400, rCodes.R500, rCodes.R600]} />}>
              <Route path="/storageTanks/list" element={<StorageTankEntity />} />
            </Route>
            <Route element={<AuthorizedProtect roles={[rCodes.R100, rCodes.R200, rCodes.R300, rCodes.R400, rCodes.R500, rCodes.R600]} />}>
              <Route path="/reporting" element={<Reporting />} />
            </Route>

            <Route element={<AuthorizedProtect roles={[rCodes.R300, rCodes.R1100]} />}>
              <Route path="/create-delivery-order" element={<CreateDeliveryOrder />} />
              <Route path="/edit-delivery-order/:id" element={<EditDeliveryOrder />} />
            </Route>

            <Route element={<AuthorizedProtect roles={[rCodes.R300]} />}>
              <Route path="/create-filling-advice-note" element={<CreateFillingAdviceNote />} />
              <Route path="/edit-filling-advice-note/:id" element={<EditFillingAdviceNote />} />
            </Route>

            <Route element={<AuthorizedProtect roles={[rCodes.R100, rCodes.R200, rCodes.R300, rCodes.R400, rCodes.R500, rCodes.R600, rCodes.R700, rCodes.R1100]} />}>
              <Route path="/transfer-orders/list" element={<AppFilterContextProvider><OrderList /></AppFilterContextProvider>} />
              <Route path="/transfer-order-detail/:id" element={<OrderDetail />} />
              <Route path="/delivery-orders/list" element={<AppFilterContextProvider><DeliveryOrderList /></AppFilterContextProvider>} />
              <Route path="/delivery-order-detail/:id" element={<DeliveryOrderDetail />} />
            </Route>
            <Route element={<AuthorizedProtect roles={[rCodes.R100, rCodes.R200, rCodes.R300, rCodes.R400, rCodes.R500, rCodes.R600, rCodes.R700]} />}>
              <Route path="/filling-advice-notes/list" element={<AppFilterContextProvider><FillingAdviceNoteList /></AppFilterContextProvider>} />
              <Route path="/filling-advice-note-detail/:id" element={<FillingAdviceNoteDetail />} />
            </Route>
          </Route>

          {/* Page not found */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
